.table-responsive {
  overflow-x: auto;
}

.thead {
  border-radius: 20px;
}
table {
  width: 100%;
  /* border-collapse: separate; */
}

.custom-table {
  border-collapse: separate;
  width: 100%;
  margin-bottom: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  margin-top: 20px;
  border: 1px solid #dee2e6;
  border-radius: 20px;
}

.custom-table th,
.custom-table td {
  text-align: center;
  padding: 8px;
}
.markname {
  padding-right: 78px !important;
}

/* .custom-table th {
  background-color: #78266a;
}

.custom-table td {
  border-top: none; 
  background: white;
} */

/* .thead th:first-child {
  border-radius: 10px 0 0 0; */
/* Adjust the border radius for the first th */
/* } */

/* .thead th:last-child { */
/* border-radius: 0 10px 0 0; */
/* Adjust the border radius for the last th */
/* } */
