.div{
    display: flex;
    justify-content: center;
 
}
  
  #gradetext {
    color: #025ff7;
    font-size: medium;
    text-align: center;
    background-color: #b6d1fc;
    border-radius: 2ch;
 

    font-weight: 500;
   



  }
  .teacherchat{
    background-color:#fff;
  
    margin-top: 5vh;
  }

  .chat-box{
    flex-direction:row;
   

  }
  .chat-item-image-box{
    flex-direction: row;
    align-items: center;
    background-color: #025ff7;
  }


  