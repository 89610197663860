@charset "UTF-8";
/* Ion.RangeSlider
// css version 2.0.3
// © 2013-2014 Denis Ineshin | IonDen.com
// ===================================================================================================================*/
/* =====================================================================================================================
// RangeSlider */
.irs {
  position: relative;
  display: block;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.irs-line {
  position: relative;
  display: block;
  overflow: hidden;
  outline: none !important; }

.irs-line-left, .irs-line-mid, .irs-line-right {
  position: absolute;
  display: block;
  top: 0; }

.irs-line-left {
  left: 0;
  width: 11%; }

.irs-line-mid {
  left: 9%;
  width: 82%; }

.irs-line-right {
  right: 0;
  width: 11%; }

.irs-bar {
  position: absolute;
  display: block;
  left: 0;
  width: 0; }

.irs-bar-edge {
  position: absolute;
  display: block;
  top: 0;
  left: 0; }

.irs-shadow {
  position: absolute;
  display: none;
  left: 0;
  width: 0; }

.irs-slider {
  position: absolute;
  display: block;
  cursor: default;
  z-index: 1; }

.irs-slider.type_last {
  z-index: 2; }

.irs-min {
  position: absolute;
  display: block;
  left: 0;
  cursor: default; }

.irs-max {
  position: absolute;
  display: block;
  right: 0;
  cursor: default; }

.irs-from, .irs-to, .irs-single {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  cursor: default;
  white-space: nowrap; }

.irs-grid {
  position: absolute;
  display: none;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20px; }

.irs-with-grid .irs-grid {
  display: block; }

.irs-grid-pol {
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 8px;
  background: #000; }

.irs-grid-pol.small {
  height: 4px; }

.irs-grid-text {
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  text-align: center;
  font-size: 9px;
  line-height: 9px;
  padding: 0 3px;
  color: #000; }

.irs-disable-mask {
  position: absolute;
  display: block;
  top: 0;
  left: -1%;
  width: 102%;
  height: 100%;
  cursor: default;
  background: rgba(0, 0, 0, 0);
  z-index: 2; }

.lt-ie9 .irs-disable-mask {
  background: #000;
  filter: alpha(opacity=0);
  cursor: not-allowed; }

.irs-disabled {
  opacity: 0.4; }

.irs-hidden-input {
  position: absolute !important;
  display: block !important;
  top: 0 !important;
  left: 0 !important;
  width: 0 !important;
  height: 0 !important;
  font-size: 0 !important;
  line-height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  overflow: hidden;
  outline: none !important;
  z-index: -9999 !important;
  background: none !important;
  border-style: solid !important;
  border-color: transparent !important; }

/* Ion.RangeSlider, Modern Skin
// css version 2.0.3
// © Denis Ineshin, 2014    https://github.com/IonDen
// ===================================================================================================================*/
/* =====================================================================================================================
// Skin details */
.irs-line-mid, .irs-line-left, .irs-line-right, .irs-bar, .irs-bar-edge, .irs-slider {
  /* background: url(../images/sidebar-menu/e-learning.svg) repeat-x; */
 }

.irs {
  height: 50px; }

.irs-with-grid {
  height: 70px; }

.irs-line {
  height: 6px;
  top: 25px; }

.irs-line-left {
  height: 6px;
  background-position: 0 -30px; }

.irs-line-mid {
  height: 6px;
  background-position: 0 0; }

.irs-line-right {
  height: 6px;
  background-position: 100% -30px; }

.irs-bar {
  height: 6px;
  top: 25px;
  background-position: 0 -60px; }

.irs-bar-edge {
  top: 25px;
  height: 6px;
  width: 6px;
  background-position: 0 -90px; }

.irs-shadow {
  height: 5px;
  top: 25px;
  background: #000;
  opacity: 0.25; }

.lt-ie9 .irs-shadow {
  filter: alpha(opacity=25); }

.irs-slider {
  width: 11px;
  height: 18px;
  top: 31px;
  background-position: 0 -120px; }
  .irs-slider.state_hover, .irs-slider:hover {
    background-position: 0 -150px; }

.irs-min, .irs-max {
  color: #999;
  font-size: 10px;
  line-height: 1.333;
  text-shadow: none;
  top: 0;
  padding: 1px 3px;
  background: #e1e4e9;
  border-radius: 4px; }

.irs-from, .irs-to, .irs-single {
  color: #fff;
  font-size: 10px;
  line-height: 1.333;
  text-shadow: none;
  padding: 1px 5px;
  background: #4466f2;
  border-radius: 4px; }

.irs-from:after, .irs-to:after, .irs-single:after {
  position: absolute;
  display: block;
  content: "";
  bottom: -6px;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -3px;
  overflow: hidden;
  border: 3px solid transparent;
  border-top-color: #4466f2; }

.irs-grid {
  height: 34px; }

.irs-grid-pol {
  background: #c0c0c0; }

.irs-grid-text {
  bottom: 12px;
  color: #c0c0c0; }
/*# sourceMappingURL=range-slider.css.map */
