.grade-checkboxes {
  /* display: flex; */
  flex-wrap: wrap;
  margin-left: 28px;
  margin-top: 10px;
}

.grade-checkbox {
  margin-right: 20px;
  margin-bottom: 10px;
}

.selected-subject {
  margin-top: 10px;
}

.grade {
  font-size: 16px;
  /* color: black; */
  margin-left: 28px;
  margin-top: 10px;
  font-weight: 500;
}
.button_ds {
  display: flex;
  justify-content: flex-end;
}

.datalist {
  background-color: gray;
  color: red;
}

.margin_top {
  margin-top: 20px;
}
.margin_top1 {
  margin-top: 50px;
}

.error-message {
  color: red;
  font-size: 14px;
}

#exampleDataList {
  background-image: url("../images/drodown.png");
  background-size: 10px;
  background-repeat: no-repeat;
  padding-right: 3.5rem;
  background-position: right;
  background-position: calc(100% - 15px) center;
  color: #78266a;
}
#exampleDataList ::placeholder {
  color: #78266a;
}
#exampleDataList::-webkit-calendar-picker-indicator {
  /* display: none; */
  opacity: 0;
}

/* CSS for customizing the dropdown icon */
.custom-dropdown-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none; /* Ensure the icon doesn't interfere with input */
}

.custom-dropdown-icon::before {
  content: "\25BC"; /* Unicode for a downward-pointing triangle */
  font-size: 14px; /* Adjust size as needed */
  color: #777; /* Adjust color as needed */
}
