.video-conf {
    font-family: "Poppins";
}

.video-conf .vc-card {
    background: #FFFFFF;
    border: 1px solid #E6EDEF;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
}

.video-conf .vc-card .vc-header .vc-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    color: #27272E;
}

.video-conf .vc-card .vc-header .vc-time {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: rgba(30, 10, 27, 0.6);
}

.video-conf .vc-card .vc-header .vc-class {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #FFFFFF;
    background: linear-gradient(229.12deg, #B23A9C 3.8%, #79266A 95.87%);
    /* width: 40px; */
    /* height: 20px; */
    padding: 2px 3px;
    border-radius: 3px;
    text-align: center;
}


.video-conf .vc-card .participant {
    margin-top: 13px;
}

.video-conf .vc-card .participant .part-title {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: rgba(30, 10, 27, 0.6);
    margin-bottom: 5px;
}

.video-conf .vc-card .participant .part-img-box {
    display: flex;
}

.video-conf .vc-card .participant .part-img-box img:nth-child(1) {
    margin-left: 0;
}

.video-conf .vc-card .participant .part-img-box img {
    z-index: 99;
    margin-left: -7px;
    border: 2px solid #fff;
    border-radius: 50%;
}

.video-conf .vc-card .participant .part-img-box .part-count {
    background: #FFFFFF;
    border: 0.5px solid rgba(119, 37, 105, 0.5);
    font-weight: 500;
    font-size: 8px;
    color: #954386;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: block;
    text-align: center;
    padding: 5px;
    margin-left: -7px;
    z-index: 999;
    margin-top: 3px;
}

.video-conf .vc-card .participant .join-btn {
    background: #ACC827;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #FFFFFF;
    padding: 6px;
    min-width: 67px;
    width: 100%;
    height: 30px;
    border: none;
    border-radius: 5px;
}