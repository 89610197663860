@media only screen and (max-width: 1440px) {}

@media only screen and (max-width: 1024px) {
    .viewannouncementnew .announcement-description-wrapper .img-slider-wrapper .carousel-control-next .carousel-control-next-icon {
        transform: translateX(52px);
    }

    .viewannouncementnew .announcement-description-wrapper .img-slider-wrapper .carousel-control-prev .carousel-control-prev-icon {
        transform: translateX(-52px);
    }

    .viewannouncementnew .announcement-description-wrapper .docs-wrapper {
        justify-content: flex-start;
    }

    .viewannouncementnew .announcement-description-wrapper .docs-wrapper img {
        height: 180px;
        width: 250px;
    }

    .video-conf .vc-card {
        padding: 10px;
    }

    .video-conf .vc-card .vc-header .vc-class {
        font-size: 9px;
    }

    .library .books-main-wrapper .my-books .book-card {
        padding: 10px;
    }

    .library .books-main-wrapper .my-books .book-card .bc-title {
        line-height: 20px;

    }

    .library .books-main-wrapper .my-books .book-card .bc-author {
        margin-bottom: 5px;
    }

    .library .books-main-wrapper .my-books .book-card .bc-img-box img {
        width: 100%;
    }

    .MyDiary .program-card .pc-details .diary-title p {
        font-size: 16px;
    }

    .MyDiary .program-card .pc-details .diary-sub-wrapper {
        display: block;
    }

    .MyDiary .program-card .pc-details .diary-sub-wrapper .view-note {
        padding: 4px 7px;
    }

    .MyDiary .program-card .pc-details .diary-sub-wrapper .subject {
        padding: 2px 18px;
    }
}

@media only screen and (max-width: 991px) {
    .viewannouncementnew .announcement-description-wrapper .img-slider-wrapper .carousel-control-next .carousel-control-next-icon {
        transform: translateX(69px);
    }

    .viewannouncementnew .announcement-description-wrapper .img-slider-wrapper .carousel-control-prev .carousel-control-prev-icon {
        transform: translateX(-69px);
    }

}

@media only screen and (max-width: 800px) {}

@media only screen and (max-width: 767px) {
    .viewannouncementnew .announcement-description-wrapper .img-slider-wrapper .carousel-control-next .carousel-control-next-icon {
        transform: translateX(52px);
    }

    .viewannouncementnew .announcement-description-wrapper .img-slider-wrapper .carousel-control-prev .carousel-control-prev-icon {
        transform: translateX(-52px);
    }

    .chat .chat-wrapper .chat-detail-section {
        margin-top: 20px;
    }

    .SchoolTvChannel .program-card .pc-details .pc-class-details p.title {
        font-size: 14px;
    }

    .SchoolTvChannel .program-card .pc-details .pc-class-details p.subtitle {
        font-size: 10px;
    }

    .SchoolTvChannel .program-card .pc-details .pc-class-details .time-wrapper p.time {
        font-size: 10px;
    }

    .SchoolTvChannel .video-list-section {
        margin-top: 20px;
    }

    .Forum .forum-item .forum-body .img-wrapper {
        width: 100%;
        overflow-y: auto;
    }

    .modal-body .delete-text-wrapper {
        font-size: 15px;
    }

    .modal-header .modal-title {
        font-size: 15px;
    }

    .delete-btn-wrapper .dlt-btn-1,
    .delete-btn-wrapper .dlt-btn-2 {
        width: 93px;
        height: 36px;
        font-weight: 500;
        font-size: 15px;
    }

    .modal-header .modal-title {
        padding-bottom: 10px;
    }

    .modal.show .modal-dialog {
        margin: auto 0 !important;
    }
}

@media only screen and (max-width: 580px) {
    .viewannouncementnew .announcement-img-box {
        justify-content: center;
    }

    .viewannouncementnew .announcement-title-box .at-heading {
        font-size: 25px;
        margin-bottom: 0;
    }

    .viewannouncementnew .announcement-title-box .at-subheading {
        font-size: 20px;
        margin-bottom: 0;
    }

    .viewannouncementnew .announcement-description-wrapper .img-slider-wrapper .carousel-control-next .carousel-control-next-icon {
        transform: translateX(37px);
    }

    .viewannouncementnew .announcement-description-wrapper .img-slider-wrapper .carousel-control-prev .carousel-control-prev-icon {
        transform: translateX(-37px);
    }

    .library .books-main-wrapper .my-books .book-card .bc-img-box img {
        width: 75px;
    }


}

@media only screen and (max-width: 480px) {
    .chat .chat-wrapper .chat-detail-section .chat-main-content .sender .sender-chat {
        margin-left: 10px;
    }

    .chat .chat-wrapper .chat-detail-section .chat-header p.user-name {
        margin-left: 7px;
        font-size: 14px;
    }

    .announcementnew .main-section .announcement-top button {
        font-size: 13px;
    }

    .reciever-chat {
        margin-right: 10px;
    }

    .library .books-main-wrapper .my-books .book-card .bc-img-box img {
        width: 75px;
    }
}

@media only screen and (max-width: 420px) {
    .viewannouncementnew .announcement-description-wrapper .img-slider-wrapper .carousel-control-next .carousel-control-next-icon {
        transform: translateX(15px);
    }

    .viewannouncementnew .announcement-description-wrapper .img-slider-wrapper .carousel-control-prev .carousel-control-prev-icon {
        transform: translateX(-15px);
    }

    .viewannouncementnew .announcement-description-wrapper .docs-wrapper {
        overflow-x: scroll;
    }

    .library .books-main-wrapper .my-books .book-card .bc-img-box img {
        width: 75px;
    }
}

@media only screen and (max-width: 360px) {

    .btn-wrapper .cx-btn-1,
    .btn-wrapper .cx-btn-2 {
        min-width: 150px
    }

    .btn-wrapper {
        justify-content: center;
    }

    .announcementnew .main-section .announcement-top label {
        margin-right: 10px;
    }

    .modal-header .modal-title {
        font-size: 19px;
    }

    .modal-header .btn-close,
    .modal-footer .btn-close,
    .modal-content .btn-close {
        top: 25px;
    }

    .chat .chat-wrapper .chat-detail-section .chat-header p.user-name {
        margin-left: 20px;
    }

    .chat .chat-wrapper .chat-detail-section .chat-main-content .sender .sender-chat p {
        font-size: 12px;
    }

    .chat .chat-wrapper .chat-detail-section .chat-main-content .sender .sender-chat {
        padding: 14px;
    }

    .chat .chat-wrapper .chat-detail-section .chat-main-content .sender .sender-chat {
        margin-left: 20px;
    }

    .reciever-chat {
        margin-right: 20px;
    }

    .chat .chat-wrapper .chat-detail-section .chat-main-content .reciever .reciever-chat p {
        padding: 14px;
        font-size: 12px;
    }

    .chat .chat-wrapper .chat-detail-section .chat-main-content .sender .sc-time-stamp {
        left: 13%;
        bottom: -15%;
    }

    .chat .chat-wrapper .chat-detail-section .chat-main-content .reciever .rc-time-stamp {
        right: 8%;
    }

    .announcementnew .main-section .announcement-top button {
        padding: 8px;
    }

    .library .books-main-wrapper .my-books .book-card .bc-img-box img {
        width: 75px;
    }
}



/* Resp For Chat Section */
@media only screen and (max-width: 575px) {
    .chat .chat-wrapper .chat-list-section .nav-pills .nav-link .chat-item-img-box span {
        /* left: 52%; */
    }
}