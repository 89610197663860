li input[type="radio"]:after {
  width: 16px;
  height: 16px;
  border-radius: 15px;
  top: 1px;
  left: 1px;
  position: relative;
  background-color: #ffffff;
  content: "";
  display: inline-block;
  /* visibility: visible; */
  border: 2px solid white;
}

.prio_div input[type="radio"]:checked:after {
  width: 17px;
  height: 17px;
  border-radius: 15px;
  top: 1px;
  left: 1px;
  position: relative;
  background-color: #732565;
  content: "";
  display: inline-block;
  /* visibility: visible; */
  border: 2px solid white;
  outline: 2px solid gray;
}
/* <------------Syllabus-----------> */
.acco_title {
  display: flex;
  align-items: center;
}
.acco_title-1 {
  width: 97%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* <------------CerateSyllabus-----------> */
.val-form {
  color: red;
  font-size: 12px;
}
/* <----------------> */
.syllabus-pro1 {
  padding: 20px;
  margin-bottom: 15px;
}
.Sytetxinp {
  width: 100%;
  height: 32px;
  padding: 0;
  border: 2px solid #e6edef;
}
.Sysele {
  width: 100%;
}
.menuicone {
  background-color: #9eb91c;
  display: flex;
  border-radius: 7px;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  padding: 2px 6px;
}
.menuicone1 {
  background-color: #78266a;
  display: flex;
  border-radius: 7px;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  padding: 2px 6px;
}
.menuicone2 {
  background-color: #cf1c4b;
  display: flex;
  border-radius: 7px;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  border: 1px #cf1c4b;
  /* padding: 2px 6px; */
}
.iconetable {
  display: flex;
  gap: 6px;
}
.sYna {
  width: 16%;
}
.td1of {
  color: #666970;
}
.secroW {
  font-weight: 500;
}
.edit-head {
  margin-bottom: 0;
  text-transform: capitalize;
  font-weight: 600;
  line-height: 1.3;
  color: #2c323f;
  font-size: 22px;
}
.edit-but {
  display: flex;
  justify-content: flex-end;
}
.g-ap {
  row-gap: 12px;
}
.buttcreate1 {
  border: none;
  background-color: #78266a;
  color: white;
  padding: 0.575rem 1.75rem;
  border-radius: 4px;
  margin-top: 15px;

  cursor: pointer;
}
.page-body1 {
  background-color: #202938;
}
.create-but-1 {
  display: flex;
  justify-content: flex-end;
  gap: 11px;
}
/* <-----------view syllabus----------> */
.View-Buttons {
  display: flex;
  gap: 10px;
}

.View-Buttons1 {
  font-size: 14px;
  padding: 0.375rem 1.75rem;
  font-weight: 600;
  background-color: #e76d6d;
  color: #ffffff;
  border: 1px solid #e76d6d;
  border-radius: 4px;
  cursor: pointer;
}
.View-Buttons2 {
  font-size: 14px;
  padding: 0.375rem 1.75rem;
  font-weight: 600;
  background-color: #9eb91c;
  color: #ffffff;
  border: 1px solid #9eb91c;
  border-radius: 4px;
  cursor: pointer;
}
.View-Buttons3 {
  font-size: 14px;
  padding: 0.375rem 1.75rem;
  font-weight: 600;
  background-color: #ef8400;
  color: #ffffff;
  border: 1px solid #ef8400;
  border-radius: 4px;
  cursor: pointer;
}
/* <--------Diary inside section----------> */
.colorbutton {
  display: flex;
  justify-content: space-between;
}
.colorbutton1 {
  padding: 2px 36px;
  color: white;
  background-color: #9eb91c;
  height: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
}
.colorbutton2 {
  padding: 2px 36px;
  color: white;
  background-color: #9eb91c;
  height: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
}
.colorbutton1:hover {
  padding: 2px 36px;
  color: #9eb91c;
  background-color: white;
  height: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #9eb91c;
}
.colorbutton2:hover {
  padding: 2px 36px;
  color: #9eb91c;
  background-color: white;
  height: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #9eb91c;
}
.ramanan {
  display: flex;
  justify-content: center;
}
.view_plus {
  margin-top: 45px;
  padding: 5px 9px;
  display: flex;
  align-items: center;
  background-color: #9eb91c;
  color: #ffffff;
  border: 1px solid #9eb91c;
  border-radius: 4px;
}
.createLess {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
}
.CreateFull {
  margin: 15px;
  display: flex;
  gap: 15px;
  flex-direction: column;
}
.add_but {
  border: none;
  background-color: #78266a;
  color: white;
  padding: 0.575rem 1.75rem;
  border-radius: 4px;
  margin-top: 15px;
  cursor: pointer;
}
.acc1 {
  margin-top: 20px;
  align-items: baseline;
}
.acc-div {
  display: flex;
  flex-direction: column;
}
.acc-head1 {
  border: 1px solid gray;
  margin-top: 10px;
  border-radius: 17px;
}
.acc-body1 {
  border-bottom: 1px solid gray;
  border-left: 1px solid gray;
  border-right: 1px solid gray;
  border-bottom-left-radius: 17px;
  border-bottom-right-radius: 17px;
}
.acc1 {
  display: flex;
  flex-direction: column;
}
.ah-1 {
  box-shadow: 1px 0px 7px gray;
  border-radius: 10px;
  margin-top: 10px;
}
.ac-body {
  margin-top: 12px;
  box-shadow: 0px 0px 7px gray;
  border-radius: 9px;
  margin-bottom: 0px;
}
.acc-text {
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 14px;
  color: #78266a;
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='Purple' class='bi bi-dash-circle' viewBox='0 0 16 16'%3E%3Cpath d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16'/%3E%3Cpath d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8'/%3E%3C/svg%3E");

  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.accordion-button::after {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;

  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='Purple' class='bi bi-plus-circle' viewBox='0 0 16 16'%3E%3Cpath d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16'/%3E%3Cpath d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4'/%3E%3C/svg%3E");

  background-repeat: no-repeat;
  background-size: 1.25rem;
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}
.acc-but1 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.acc-de {
  display: flex;
  gap: 11px;
  margin-right: 13px;
  color: #732565;
}
.custom-tooltip {
  background-color: #732565;
  color: white;
}
/* <---------offline assement---------> */
.of_buttons {
  display: flex;
  gap: 10px;
}
.btnn12 {
  font-size: 14px;

  font-weight: 600;
  color: #ffffff;
  background-color: transparent;
  border: none;

  padding: 11px 13px;
}
.btnn12:hover {
  font-size: 14px;

  font-weight: 600;
  color: #9eb91c;
  background-color: transparent;
  border: 1px thin #9eb91c;

  padding: 11px 13px;
}
.off_butt {
  display: flex;
  gap: 10px;
}
.crbt:hover {
  border: 1px solid #732565;
}
.f1 {
  display: flex;
  gap: 20px;
}
.gbut {
  padding: 12px 14px;
  margin-top: -8px;
  border-radius: 4px;
  background-color: #9eb91c;
  color: white;
  border: 1px solid #9eb91c;
  font-weight: 500;
}
.gbut:hover {
  padding: 12px 14px;
  margin-top: -8px;
  border-radius: 4px;
  background-color: white;
  color: #9eb91c;
  border: 1px solid #9eb91c;
}
.con {
  display: contents;
}
