.tabs {
  display: flex;
}


.tab {
  cursor: pointer;
  padding: 8px 16px;
  margin-right: 10px;
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 20px;
  color: black;
  font-family: "Popins";
  border-bottom: 2px solid transparent; /* Initially transparent */
}
body.dark-only .tab {
  cursor: pointer;
  padding: 8px 16px;
  margin-right: 10px;
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 20px;
  color: white;
  font-family: "Popins";
  border-bottom: 2px solid transparent; /* Initially transparent */
}
.tab:last-child {
  margin-right: 0;
}

/* .tab:hover {
  border-bottom-color: #ccc;
} */
body.dark-only .tab.active {
  border-bottom: 0.5px solid purple;
  color: purple;
  /* Active tab underline color */
}
.tab.active {
  border-bottom: 0.5px solid purple;
  color: purple;
  /* Active tab underline color */
}
