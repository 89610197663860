.CreateDiary {
  background-color: white;

  border-radius: 10px;
}
.main-sectionn {
  padding: 20px;
  margin-bottom: 15px;
}
.labelCreate {
  font-size: 18px;
  font-weight: 600;
}
.announcementCreate {
  border-bottom: 1px solid #e6edef;
  padding: 0 0 20px;
}
.contentHeader {
  font-size: 14px;
  font-weight: 600;
  padding-top: 20px;
}
.content {
  padding: 0px 0 0;
}
.createInput {
  width: 100%;
  border: 1px solid #e6edef;
  height: 35px;
  border-radius: 5px;
  padding-left: 10px;
}
.createInputtwo {
  width: 100%;
  border: 1px solid #e6edef;
  height: 100px;
  border-radius: 5px;
}
textarea {
  resize: none;
  border-radius: 5px;
}
.description {
}
.dropdown-toggle::after {
  display: none;
}
.radioButtons {
  display: flex;
  justify-content: space-between;
}
.contentHeader2 {
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  padding-top: 20px;
}
p {
  margin-bottom: 0;
}
.chechlist {
  display: flex;
  gap: 5px;

  align-items: center;
}
.cancelBut {
  border: 2px solid #78266a;
  background-color: white;
  color: #78266a;
  font-size: 14px;
  padding: 0.5rem 1rem;
  font-weight: 600;
  border-radius: 5px;
}
.submitBut {
  background-color: #78266a;
  color: white;
  font-size: 14px;
  padding: 0.5rem 1rem;
  font-weight: 600;
  border: none;
  border-radius: 5px;
}
.SubmitDiv {
  display: flex;
  justify-content: flex-end;
  gap: 5px;
  padding-top: 30px;
}
#specifyColor {
  accent-color: #78266a;
  border-color: black;
}
.btn:focus {
  border: none;
}

.deletebutt {
  display: flex;
  gap: 5px;
}
.closebtt {
  border: none;
  padding: 0;
  height: 15px;
  background: red;
}
.mapMain {
  display: flex;
}
/* ======syllabus======== */
.btn-edsys-sec {
  background-color: #9eb91c !important;
  border-color: #78266a00 !important;
  color: #ffffff;
  transition: background-color 1s ease;
  position: relative;
  top: -10px;
  display: flex;
  margin-right: 8px;
  border-radius: 4px;
  border: 1px solid #9eb91c;
}
.btn-edsys-sec:hover {
  background-color: #ffffff !important;
  border-color: #9eb91c !important;
  color: #9eb91c;
  transition: background-color 1s ease;
  position: relative;
  top: -10px;
  display: flex;
  margin-right: 8px;
  border-radius: 4px;
  border: 1px solid #9eb91c;
}
.btnn {
  font-size: 14px;
  padding: 0.375rem 1.75rem;
  font-weight: 600;
  color: #ffffff;
  background-color: transparent;
  border: none;
  margin-top: 7px;
}
.btnn:hover {
  font-size: 14px;
  padding: 0.375rem 1.75rem;
  font-weight: 600;
  color: #9eb91c;
  background-color: transparent;

  margin-top: 7px;
}
.SyllabusCreate {
  padding: 0px;
  background-color: white;
  border-radius: 15px;
}
.announcementCreatee {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e6edef;
  padding: 0 0 20px;
}
.CreateB {
  font-size: 14px;
  padding: 0.575rem 1.75rem;
  font-weight: 600;
  color: #ffffff;
  background-color: #9eb91c;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.CreateBB {
  font-size: 14px;
  padding: 0.575rem 1.75rem;
  font-weight: 600;
  color: #ffffff;
  background-color: #9eb91c;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding-left: 15px;
  padding-right: 15px;
}

.iconSec {
  display: flex;
  gap: 5px;
  justify-content: flex-end;
}
.searchIc {
  background-color: #9eb91c;
  display: flex;
  border-radius: 7px;
  align-items: center;
  cursor: pointer;
}
.searchInStuList {
  background-color: #9eb91c;
  display: flex;
  border-radius: 7px;
  align-items: center;
  cursor: pointer;
  padding: 8px;
}
.editInStuList {
  background-color: #78266a;
  display: flex;
  border-radius: 7px;
  align-items: center;
  cursor: pointer;
  padding: 8px;
}
.editBu {
  background-color: #78266a;
  display: flex;
  border-radius: 7px;
  align-items: center;
  cursor: pointer;
}
.icons {
  color: white;
  font-size: 15px;
}
td.textcol {
  color: rgba(36, 41, 52, 0.7);
  font-weight: 500;
  padding-left: 22px;
}
.tableHead {
  background-color: #73276b14;
}
table.newTable {
  padding: 0px;
}
.tableCreate {
  padding-top: 0px;
}
.tetxinp {
  width: 40%;
  height: 32px;
  padding: 0;
  border: 2px solid #e6edef;
}
.labelCreate {
  margin-bottom: 0;
  text-transform: capitalize;
  font-weight: 600;
  line-height: 1.3;
  color: #2c323f;
  font-size: 22px;
}
/* createsyllabus */
.textar {
  width: 100%;
  border: 1px solid #e6edef;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  padding-left: 10px;
}
.curriculum {
  padding-top: 0px;
}
.buttcreate {
  border: none;
  background-color: #78266a;
  color: white;
  padding: 0.575rem 1.75rem;
  border-radius: 4px;
  margin-top: 15px;
  width: 10%;
  cursor: pointer;
}
.tableCreatee {
  background-color: #73276b14;
  padding: 20px;
  border-radius: 5px;
}
.errors {
  color: red;
}
.tetxinpp {
  width: 100%;
  height: 39px;
  padding: 0;
  border: 1px solid #c4cdd0;
  border-radius: 5px;
  padding-left: 10px;
}
.studListsel {
  width: 100%;
  height: 32px;
  padding: 0;
  border: 2px solid #e6edef;
  color: rgba(36, 41, 52, 0.7);
}
.studListClr {
  background-color: #78266a;
  color: white;
  font-weight: 800;
  border: none;
  padding: 8px 15px 8px 15px;
  border-radius: 5px;
}
.radios {
  padding: 12px 0px 0px 0px;
  display: flex;
  gap: 5px;
}
.studDet {
  background-color: #73276b14;
  padding: 10px;
  border-radius: 5px;
}
.searchButton {
  display: flex;
  justify-content: end;
}
.searchStud {
  border: none;
  background-color: #78266a;
  color: white;
  padding: 8px 15px 8px 15px;
  border-radius: 5px;
}
.promotionClass {
  padding-bottom: 15px;
}
.tableHead th {
  padding-left: 22px;
}
.tableHeadd {
  background-color: #73276b14;
}
.promotestu {
  display: flex;
  justify-content: end;
  padding-bottom: 15px;
}
.promButt {
  border: none;
  background-color: #9eb91c;
  color: white;
  padding: 8px 15px 8px 15px;
  border-radius: 5px;
}
.alertstyle {
}
.UserText {
  color: black;
  font-size: 15px;
  font-weight: 600;
}
.cardmt {
  padding: 20px;
  background: white;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.userButt {
  display: flex;
  justify-content: end;
}
.calenderMain {
  display: flex;
  align-items: center;
  width: 100%;
}
.buttdiv {
  display: flex;
  justify-content: end;
}
.evalHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 35px;
}
.evalHea {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.evaluation {
  background-color: white;
  /* height: calc(100vh -200px); */
  padding: 25px;
  border-radius: 10px;

  margin-bottom: 22px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
.evaluationn {
  background-color: white;
  /* height: calc(100vh -200px); */
  padding: 25px;
  border-radius: 10px;

  margin-bottom: 22px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
.searchi{
  /* background-color: #9eb91c; */
  display: flex;
  gap: 5px;
  justify-content: space-evenly;
  border-radius: 5px;
  align-items: center;
  cursor: pointer;
  /* padding: 5px; */
}
.searchIcc {
  background-color: #9eb91c;
  display: flex;
  border-radius: 5px;
  align-items: center;
  cursor: pointer;
  padding: 5px;
}
.editBuu {
  background-color: #cf1c4b;
  display: flex;
  border-radius: 5px;
  align-items: center;
  cursor: pointer;
  padding: 5px;
}
.tablee {
  padding-top: 20px;
}
.tableHea {
  background-color: #73276b14;
}
.createBut {
}
.recharts-default-legend {
  margin-bottom: 35px;
}
.charts {
  height: 65vh;
  padding-top: 80px;
}
.multicolor-bar {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.values,
.scale,
.bars,
.legends {
  display: flex;
}
.bars {
  width: 100%;
  height: 28px;
}
.value,
.graduation,
.bar,
.legend {
  display: flex;
  align-items: center;
  justify-content: end;
}

.legend .dot {
  margin-right: 4px;
}
.label {
  color: black;
}
.iconss {
  color: black;
  display: flex;
  gap: 5px;
  justify-content: end;
}
.searc{
  color: white;
  /* background-color: #9eb91c; */
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  /* padding: 4px; */
  border-radius: 4px;
  cursor: pointer;
}
.sear {
  color: white;
  background-color: #9eb91c;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 4px;
  cursor: pointer;
}
.editb {
  color: white;
  background-color: #78266a;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 4px;
  cursor: pointer;
}
.del {
  color: white;
  background-color: #cf1c4b;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 4px;
  cursor: pointer;
  border: none;
}
.fontsp {
  font-weight: 500;
  font-size: 11px;
}
.gradeTextarea {
  width: 100%;
  padding-top: 12px;
  padding-bottom: 0;
  margin-bottom: 0px;
  border: 1px solid #dacece;
  font-family: sans-serif;
  padding-left: 8px;
  color: #76787d;
  text-transform: capitalize;
}
.totalGradecol {
  display: flex;
  flex-direction: column;
}
/* .maingradeStyle {
  height: 60vh;
} */
.subButt {
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
}
.subButtt {
  display: flex;
  justify-content: end;
}
.addandrem {
  display: flex;
  padding-top: 15px;
  gap: 10px;
}
.slab-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 10px;
}
.gradeClose {
  background-color: red;
  border: none;
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 5px;
  color: #ffffff;
  height: fit-content;
}
.closebutt {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #73276b14;
  border-radius: 5px;
  margin-bottom: 20px;
}
.closebuttt {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #73276b14;
  border-radius: 5px;
  margin-bottom: 20px;
  margin-top: 15px;
}

.removeSlabButton {
  display: flex;
  justify-content: end;
}
.addslab {
  border: none;
  background-color: #9eb91c;
  color: white;
  padding: 10px 10px;
  border-radius: 5px;
}
.gradetext {
  /* padding-bottom: 15px; */
  margin-bottom: 0;
  display: flex;
  align-items: center;
}
.gradetextt {
  padding-top: 10px;
  padding-bottom: 15px;
}

.progress {
  background-color: #e9ecef;
  border-radius: 0.25rem;
  height: 1.5rem;
  margin-bottom: 1rem;
  overflow: hidden;
}

.progress-bar {
  background-color: #78266a;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  height: 100%;
  transition: width 0.6s ease;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.bar-container {
  position: relative;
}
.gradee {
  color: white;
  width: 100%;
  display: flex;
  justify-content: center;
}
.line {
  position: relative;
  top: 38px;
}
.erro {
  color: red;
}
