.Forum {
    font-family: 'Poppins';
}

.Forum .main-section {
    padding: 20px;
    background-color: #FFFFFF;
    margin-bottom: 20px;
    width: -webkit-fill-available;
    border-radius: 10px 10px 0 0;
}

.Forum .main-section .announcement-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 20px;
    border-bottom: 1px solid #E6EDEF;
}

.Forum .main-section .announcement-top label {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #000000;
}

.Forum .forum-item {
    background: #FFFFFF;
    border: 1px solid #E6EDEF;
    border-radius: 20px;
    margin: 20px 0 0;
}

.Forum .forum-item .forum-header {
    display: flex;
    padding: 20px;
    border-bottom: 1px solid #E6EDEF;
}

.Forum .forum-item .forum-header .forum-img {}

.Forum .forum-item .forum-header .forum-img img {}

.Forum .forum-item .forum-header .forum-title-box {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.Forum .forum-item .forum-header .forum-title-box .forum-title-wrapper {
    padding-left: 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.Forum .forum-item .forum-header .forum-title-box .forum-title-wrapper p {
    margin: 0;
}

.Forum .forum-item .forum-header .forum-title-box .forum-title-wrapper p.ft-main-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #051B26;
}

.Forum .forum-item .forum-header .forum-title-box .forum-title-wrapper p.ft-sub-title {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: rgba(30, 10, 27, 0.6);
}

.Forum .forum-item .forum-header .forum-title-box .forum-title-wrapper p.ft-date {
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    color: #1E0A1B;

}

.Forum .forum-item .forum-header .forum-title-box .forum-dropdown {}

.Forum .forum-item .forum-header {}

.Forum .forum-item .forum-body {
    border-bottom: 1px solid #E6EDEF;
    padding: 20px;
}

.Forum .forum-item .forum-body .text-wrapper {}

.Forum .forum-item .forum-body .text-wrapper p {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #939191;
    /* padding: 20px; */
}

.Forum .forum-item .forum-body .text-wrapper p a {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #79266A;
}

.Forum .forum-item .forum-body .img-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.Forum .forum-item .forum-body .img-wrapper img {
    margin-right: 10px;
    width: 160px;
    height: 90px;
    border-radius: 10px;
}


.Forum .forum-footer {
    padding: 20px;
    display: flex;
    align-items: center;
}

.Forum .forum-footer .like-btn {}

.Forum .forum-footer span {
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    color: #772569;

}

.Forum .forum-footer img {
    width: 16px;
    height: 16px;
    margin-right: 5px;
}

.Forum .forum-footer .like-btn,
.Forum .forum-footer .dislike-btn,
.Forum .forum-footer .comments-btn {
    margin-right: 20px;
    cursor: pointer;
}

.Forum .forum-footer .like-btn {}

.Forum .forum-footer .dislike-btn {}

.Forum .forum-footer .comments-btn {}

.Forum .forum-img-wrapper {
    width: 100%;
}

.Forum .forum-img-wrapper img {
    width: 100%;
}

.Note_view .forum-footer {
    margin-bottom: 20px;
}

.Forum .top-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Forum .top-btn-wrapper button {
    background: #ABC73E;
    font-weight: 500;
    font-size: 16px;
    border-radius: 5px;
    padding: 8px 21px;
    border: none;
    color: #FFFFFF;
    line-height: normal !important;
    font-family: "Poppins";
    margin-right: 20px;
}

.Forum .top-btn-wrapper button:focus {
    outline: none;
}

.Forum .top-btn-wrapper button img {
    margin-right: 7px;
}

.Forum .top-btn-wrapper img {
    cursor: pointer;
}