.cuscontainer {
  position: relative;
}

.combined-input {
  width: 50%;
  height: 40px;
  border: 1px solid #e6edef;
  padding-left: 5px;
  font-family: sans-serif;
  border-radius: 0.25rem;
  background-color: white;
}

.cusdropdown {
  position: absolute;
  width: 50%;
  background-color: white;
  border: 1px solid #e6edef;
  border-radius: 0.25rem;

  z-index: 20;
}

.cusdropdown-item {
  padding: 5px;
  cursor: pointer;
  border-bottom: 1px solid black;
}

.cusdropdown-item:last-child {
  border-bottom: none;
}
.combined-input {
  width: 100%;
  height: 40px;
  border: 1px solid #e6edef;
  padding-left: 5px;
  font-family: sans-serif;
  border-radius: 0.25rem;
}

.invalid {
  border-color: red; /* Change border color to red when input is invalid */
}

.error-text {
  color: red; /* Change text color to red for error message */
  font-size: 12px;
  margin-top: 3px;
}
.DropTxt {
  margin-top: 10px;
  margin-bottom: -0.5px;
  line-height: 30px;
}
.slow-hover-button {
  background-color: #007f00; /* Default button color */
  color: red; /* Default text color */
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: background-color 0.5s ease, color 0.5s ease; /* Add color transition */
}

.slow-hover-button::before {
  content: "";
  position: absolute;
  top: 0;
  right: 100%; /* Initially positioned outside the button */
  width: 100%;
  height: 100%;
  background-color: rgba(0, 255, 0, 0.5); /* Hover color */
  transition: right 0.5s ease; /* Transition the right property */
}

.slow-hover-button:hover::before {
  right: 0; /* Move the hover color gradually from right to left */
}

.slow-hover-button:hover {
  color: white; /* Change text color to white on hover */
}
