.container {
  margin-top: 1em;
  margin-bottom: 4em;
}
.loading-skeleton h1,
.loading-skeleton h2,
.loading-skeleton h3,
.loading-skeleton h4,
.loading-skeleton h5,
.loading-skeleton h6,
.loading-skeleton p,
.loading-skeleton li,
.loading-skeleton .btn,
.loading-skeleton label,
.loading-skeleton .form-control {
  color: transparent;
  appearance: none;
  -webkit-appearance: none;
  background-color: #e7eef0;
  border-color: #e7eef0;
}
.loading-skeleton h1::placeholder,
.loading-skeleton h2::placeholder,
.loading-skeleton h3::placeholder,
.loading-skeleton h4::placeholder,
.loading-skeleton h5::placeholder,
.loading-skeleton h6::placeholder,
.loading-skeleton p::placeholder,
.loading-skeleton li::placeholder,
.loading-skeleton .btn::placeholder,
.loading-skeleton label::placeholder,
.loading-skeleton .form-control::placeholder {
  color: transparent;
}
@keyframes loading-skeleton {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
.loading-skeleton {
  pointer-events: none;

  animation: loading-skeleton 0.7s infinite alternate;
}
.loading-skeleton img {
  filter: grayscale(100) contrast(0%) brightness(1.8);
  height: 180px;
  border-radius: 0.5rem;
}

.loading-skeleton .card {
  height: 170px;
  border-radius: 0.5rem;
}

.loading-skeleton .title {
  border-radius: 0.5rem;
}
.stats-card {
  height: 225px;
}

.loading-skeleton .channel_card {
  height: 120px;
  width: 265px;
  border-radius: 15px;
}

.loading-skeleton .timetable_card {
  width: 134px !important;
}
.loading-skeleton .timetable_inner_card {
  width: 135px !important;
  height: 57.2px;
  margin-bottom: 7px !important;
}

.loading-skeleton .period_card {
  height: 117.6px !important;
}

.loading-skeleton .assignment_card_skeleton {
  border-radius: 0px;
  height: 500px;
}
/* SyllabusCreate.css */

.SyllabusCreate {
  padding: 20px;
  background-color: #f9f9f9;
  min-height: 100vh;
}

.main-sectionn {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.announcementCreatee {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.labelCreate {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.createButt {
  color: #fff;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
}

.createButt .CreateB {
  font-size: 16px;
  font-weight: bold;
}

.createButt:hover {
}

.tablesec {
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  border-collapse: collapse;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
  padding: 12px;
  text-align: left;
  width: 50%;
}

.error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

/* Skeleton Loader CSS */
.skeleton-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.skeleton-wrapper .skeleton-label {
  width: 200px;
  height: 30px;
}

.skeleton-wrapper .skeleton-button {
  width: 100px;
  height: 30px;
}

.skeleton-wrapper .skeleton-table th,
.skeleton-wrapper .skeleton-table td {
  width: 100%;
  height: 20px;
}
/* SyllabusCreate.css */

.SyllabusCreate {
  padding: 20px;
  background-color: #f9f9f9;
  min-height: 100vh;
}

.main-sectionn {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.announcementCreatee {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.labelCreate {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.createButt {
  color: #fff;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
}

.createButt .CreateB {
  font-size: 16px;
  font-weight: bold;
}

.tableCreate {
  margin-top: 0px;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  border-collapse: collapse;
}

.table-borderless th,
.table-borderless td {
  border: 0;
}

.textcol {
  color: #fff;
}

.iconSec {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.searchIc,
.editBu {
  cursor: pointer;
}

.icons {
  color: #fff;
  font-size: 18px;
}

/* Skeleton Loader CSS */
.skeleton-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.skeleton-wrapper .skeleton-label {
  width: 200px;
  height: 30px;
}

.skeleton-wrapper .skeleton-button {
  width: 100px;
  height: 30px;
}

.skeleton-wrapper .skeleton-table th,
.skeleton-wrapper .skeleton-table td {
  width: 100%;
  height: 20px;
}
@media screen and (max-width: 767px) {
  .punch-i {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (max-width: 767px) {
  .punch-o {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (max-width: 517px) {
  .my-attendance .btn-apply-leave {
    right: 16px;
  }
}
.text-inp1 {
  padding-left: 10px;
}
