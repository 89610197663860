.subjectIcon {
    height: 40px;
    width: 40px;
}

.class_name {
    background-color: rgb(9, 120, 231);
    border-radius: 7px;
    /* font-size: 12px; */
    padding: 2px 8px;
    height:20px;
    color: #FFF;
}

.bodyText{
    color: #772569
}
