.table th,
.table td {
  /* height: 100px; */
  vertical-align: top;
}
.table tr {
  overflow-x: auto;
}

.table td {
  position: relative;
}
/* .table {
  width: 70%;
} */

.badge {
  /* position: absolute; */
  top: 5px;
  left: 5px;
}

.today {
  color: white;
}

.otherMonth {
  opacity: 50%;
  color: gray;
}
.prev {
  border: none;
  background-color: #9eb91c;
  display: flex;
  color: white;
  gap: 8px;
  border-radius: 5px;
  padding: 5px 5px;
  align-items: center;
}
.containerr {
  border: 1px solid #dee2e6;
  padding: 25px;
  border-radius: 10px;
  height: 480px;
}
